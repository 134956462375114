/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XInput from 'vux/src/components/x-input';
import Datetime from 'vux/src/components/datetime';
import XTextarea from 'vux/src/components/x-textarea';
import XButton from 'vux/src/components/x-button';
import UploadImageRequired from '@/components/UploadImage/UploadImageRequired';
import atitle from '@/components/Approve/atitle';
import { Dialog, Toast } from 'vant'; // -----审批节点

import FixedNode from '@/components/Approve/FixedNode';
import AssignNode from '@/components/Approve/AssignNode';
import OfficersNode from '@/components/Approve/OfficersNode';
import NoAssiginNode from '@/components/Approve/NoAssiginNode';
import copyOffNode from '@/components/Approve/copyOffNode';
import copyNoAssiginNode from '@/components/Approve/copyNoAssiginNode';
import copyFixedNode from '@/components/Approve/copyFixedNode';
export default {
  name: 'businessDetails',
  components: {
    XInput: XInput,
    Datetime: Datetime,
    XTextarea: XTextarea,
    XButton: XButton,
    UploadImageRequired: UploadImageRequired,
    atitle: atitle,
    FixedNode: FixedNode,
    AssignNode: AssignNode,
    OfficersNode: OfficersNode,
    NoAssiginNode: NoAssiginNode,
    copyOffNode: copyOffNode,
    copyNoAssiginNode: copyNoAssiginNode,
    copyFixedNode: copyFixedNode
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.$utils.Store.removeItem('kssj');
    this.$utils.Store.removeItem('jssj');
    this.$utils.Store.removeItem('outPlace');
    this.$utils.Store.removeItem('reason');
    next();
  },
  data: function data() {
    return {
      actions: this.$api.Base.dev + '/management/insertPhoto',
      //上传图片接口
      bh: '00110',
      //假类编号
      //				roomName:'',    //会议室名称
      outPlace: this.$utils.Store.getItem('outPlace') ? this.$utils.Store.getItem('outPlace') : '',
      //目的地
      startDate: '',
      //开始日期
      endDate: '',
      //截止日期
      kssj: this.$utils.Store.getItem('kssj') ? this.$utils.Store.getItem('kssj') : '',
      //开始时间
      jssj: this.$utils.Store.getItem('jssj') ? this.$utils.Store.getItem('jssj') : '',
      //结束时间
      minuteList: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      //定义分钟列表
      reason: this.$utils.Store.getItem('reason') ? this.$utils.Store.getItem('reason') : '',
      //外出目的
      approver: this.$utils.Store.getItem('oldVal') ? JSON.parse(this.$utils.Store.getItem('oldVal')) : [],
      //审批人
      approverChecked: [],
      //审批人序号
      inputParam: [{
        name: 'att_id',
        value: 0
      }, {
        name: 'base64String',
        value: ''
      }, {
        name: 'typeId',
        value: -1
      }],
      imgList: [],
      //图片列表数组
      limitNum: 5,
      //最多上传图片张数
      imgPath: [],
      //图片地址
      imgIndex: 0,
      //图片数组下标
      icon: 'icon-jiahao',
      //icon加号
      //  ----以下是lwt的变量--2020-07-01
      approve: [],
      //要提交的审批节点列表
      allUserList: [],
      //获取过来的审批节点
      process: 0,
      //0简单审批 1条件审批
      userSerial: this.$utils.Store.getItem('userSerial'),
      //人员编号
      approveConditionId: '',
      approveSettingId: '',
      cc: {},
      //抄送人
      haveCC: false,
      //有没有抄送人
      showLevel: '',
      sbmitDis: true,
      //是否可以点击
      approveStatues: false,
      //控制提交状态isDisabled
      chaosongzhuangtai: false //控制提交状态isDisabled

    };
  },
  computed: {
    isDisabled: function isDisabled() {
      if (this.kssj != '' && this.jssj != '' && this.outPlace != '' && this.reason != '' && this.sbmitDis) {
        /**/
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    /* 2021/11/15 优化增加删除不影响业务*/
    getShenpiRenshu: function getShenpiRenshu(aa) {
      /* 审批人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.approveStatues = true;
      } else if (aa == 0) {
        this.approveStatues = false;
      }
    },

    /* 2021/11/15 优化增加删除不影响业务*/
    gechaosongrenshu: function gechaosongrenshu(aa) {
      /*抄送人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.chaosongzhuangtai = true;
      } else if (aa == 0) {
        this.chaosongzhuangtai = false;
      }
    },

    /** function 获取服务器时间---今日日期
     *   wzx
     *   2019-01-11
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          Toast(msg);
        } else {
          _this.startDate = data.sysDate.substring(0, 10);
          _this.kssj = data.sysDate.substring(0, 16);
          _this.jssj = _this.dateEnd(_this.kssj);

          _this.getNodeList();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** function 获取目的地
     *   wzx
     *   2019-01-05
     **/
    getPlace: function getPlace(val) {
      this.outPlace = val;
      this.$utils.Store.setItem('outPlace', val);
    },

    /** function 获取外出开始时间
     *   wzx
     *   2019-01-05
     **/
    changeStartTime: function changeStartTime(val) {
      this.kssj = val;
      this.$utils.Store.setItem('kssj', val);
      this.startDate = val.substring(0, 10); // this.endHour = parseInt(val.substring(11,13));

      /*if(this.jssj != '' && this.kssj>this.jssj){
      this.$utils.Tools.toastShow("开始时间不能大于结束时间");
      setTimeout(()=>{
          this.kssj = '';
      },2000)
      }*/
    },

    /** function 获取外出结束时间
     *   wzx
     *   2019-01-05
     **/
    changeEndTime: function changeEndTime(val) {
      this.jssj = val;
      this.$utils.Store.setItem('jssj', val);
      this.endDate = val.substring(0, 10);
    },

    /** function 获取外出事由
     *   wzx
     *   2019-01-05
     **/
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);
    },
    onChange: function onChange() {},
    onShow: function onShow() {},
    onHide: function onHide() {},
    // ----判断什么方式获取审批节点
    changeget: function changeget() {
      var _this2 = this;

      this.allUserList = [];
      this.leaveList.some(function (item) {
        if (_this2.bh == item.value && item.process == 0) {
          _this2.getNodeList();

          return true;
        } else if (_this2.bh == item.value && item.process == 1) {
          if (_this2.startDate && _this2.endDate) {
            _this2.getNodeList();

            return true;
          }
        }
      });
    },

    /** function 遍历数组是否少
     *   wzx
     *  202-0706
     **/
    ergArray: function ergArray(list) {
      if (!list) {
        return true;
      }

      var uindex = list.findIndex(function (item) {
        return !item.userSerial[0];
      });
      return uindex != -1;
    },

    /** function 提交出差申请
     *   wzx
     *   2019-01-05
     * */
    submitClick: function submitClick() {
      var _this3 = this;

      if (this.kssj > this.jssj) {
        Toast('开始时间不能大于结束时间');
        return;
      } else if (this.kssj == this.jssj) {
        Toast('开始时间不能与结束时间相同');
        return;
      } else if (this.ergArray(this.approve)) {
        Toast('未选择审批人');
        return;
      } else if (this.haveCC && !this.cc.userSerial[0]) {
        Toast('未选择抄送人');
        return;
      } else {
        this.sbmitDis = false;
        this.approverChecked = [];
        this.imgPath = [];

        var _iterator = _createForOfIteratorHelper(this.imgList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var imgs = _step.value;
            this.imgPath.push(imgs.serverId);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        var imgPath = JSON.stringify(this.imgPath).replace(/\[|]|"/g, '');
        var businessEntity = {
          bh: this.bh,
          bz: this.reason,
          imgPath: imgPath,
          kssj: this.kssj,
          jssj: this.jssj,
          leaveLength: this.leaveLength === null ? 0 : this.leaveLength,
          processId: '',
          qjlx: 1,
          userSerial: this.$utils.Store.getItem('userSerial'),
          outPlace: this.outPlace
        };
        var params = {
          businessEntity: businessEntity,
          approveCode: 2,
          approveSettingId: this.approveSettingId,
          approveConditionId: this.approveConditionId,
          approveCodeSub: this.bh,
          petitioner: this.$utils.Store.getItem('userSerial'),
          cc: [this.cc],
          approve: this.approve,
          showLevel: this.showLevel
        };
        this.$utils.Tools.loadShow();
        this.$api.Appr.apply(params).then(function (response) {
          _this3.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;
          _this3.sbmitDis = true;

          if (code == '625' || code == '626') {
            _this3.$utils.Tools.loadHide();

            Dialog.alert({
              title: '提示',
              message: msg,
              messageAlign: 'left',
              width: '70%',
              closeOnPopstate: true
            }).then(function () {
              // on close
              _this3.$router.go(0);
            });
            return;
          }

          if (+code !== _this3.$code.success) {
            _this3.$utils.Tools.toastShow(msg);
          } else {
            _this3.$utils.Tools.toastShow('提交成功');

            _this3.$router.go(-1); // this.$router.replace('/MyApply')

          }
        }).catch(function () {
          _this3.$utils.Tools.httpError();
        });
      }
    },

    /** function 当前提交审批节点中是否有对应的节点
     *   lwt
     *   2020-07-03
     * */
    inspectObj: function inspectObj(sub) {
      var index = 0;
      var flag = this.approve.some(function (items, i) {
        if (items.level == sub.level) {
          index = i;
          return true;
        }
      });
      return {
        index: index,
        flag: flag
      };
    },

    /** function 获取子集的节点对象
     *   lwt
     *   2020-07-03
     * */
    getSubUserObj: function getSubUserObj(appr) {
      if (appr.cc && appr.cc == 1) {
        this.cc = appr;
        return;
      }

      if (this.approve.length == 0) {
        this.approve.push(appr);
      } else {
        var insUser = this.inspectObj(appr);

        if (insUser.flag) {
          //如果有重复的
          this.approve[insUser.index] = appr;
        } else {
          //没有重复的
          this.approve.push(appr);
        }
      }
    },

    /** function 获取可审批的节点
     *   lwt
     *   2020-07-04
     * */
    getNodeList: function getNodeList() {
      var _this4 = this;

      this.haveCC = false;
      this.cc = {};
      this.$utils.Tools.loadShow();
      var params = {
        approveCode: '2',
        //公出
        subCode: this.bh,
        startTime: this.kssj,
        //开始时间
        endTime: this.jssj,
        //结束时间
        userSerial: this.userSerial //人员序列

      };
      this.$api.Appr.getApplicants(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == '602') {
          _this4.$utils.Tools.loadHide();

          Dialog({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
          return;
        }

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.loadHide();

          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.$utils.Tools.loadHide();

          _this4.showLevel = data.showLevel;

          if (data.showLevel == '1') {
            _this4.allUserList = data.approve;
          } else {
            for (var i = 0; i < data.approve.length; i++) {
              if (data.approve[i].level < data.showLevel) {
                (function () {
                  var userItem = {};
                  var user = [];
                  data.approve[i].user.some(function (sub) {
                    user.push(sub.userSerial);
                  });
                  userItem.level = data.approve[i].level;
                  userItem.way = data.approve[i].levelWay;
                  userItem.levelId = data.approve[i].levelId;
                  userItem.userSerial = user;

                  _this4.approve.push(userItem);
                })();
              } else {
                _this4.allUserList.push(data.approve[i]);
              }
            }
          }

          if (data.ccSet) {
            _this4.haveCC = true;
            _this4.allUserList[_this4.allUserList.length] = data.ccSet; //抄送
          }

          _this4.approveConditionId = data.approveConditionId;
          _this4.approveSettingId = data.approveSettingId;
          _this4.process = data.process;
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /** function 开始时间后一个小时
     *   lwt
     *  2020-07-14
     **/
    dateEnd: function dateEnd(val) {
      var atime = val.replace(/-/g, '/');
      var now = new Date(atime);
      var time = now.getTime() + 1000 * 60 * 60;
      var dd = new Date(time); // let dd = new Date()

      var seperator1 = '-';
      var seperator2 = ':'; //   dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期

      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期

      var d = dd.getDate();
      var h = dd.getHours();
      var mm = dd.getMinutes();
      m = m > 9 ? m : '0' + m; //格式化时间

      d = d > 9 ? d : '0' + d;
      h = h > 9 ? h : '0' + h;
      mm = mm > 9 ? mm : '0' + mm;
      return y + seperator1 + m + seperator1 + d + ' ' + h + seperator2 + mm;
    }
  },
  created: function created() {
    this.getSysDate();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('oldVal');
  }
};